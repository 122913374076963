// {
//   id: 1,
//   logoImage: '/assets/images/Estimate/interior.png',
//   bannerImage: '/assets/images/Home/interior.png',
//   alt: 'sun white sheer curtain',
//   content: 'SUN WHITE SHEER\nCURTAIN',
//   price: 150,
//   category: 'sheer',
//   title: 'SUN WHITE SHEER CURTAIN',
//   theme: 'left-align',
//   highlight: 'I need Privacy with a Subtle Glow!',
//   description:
//     'Enjoy privacy without sacrificing natural light. Our White Sheer Curtains strike the perfect balance, allowing sunlight to filter through while providing a gentle, soft glow that adds warmth and elegance to your rooms.',
// },
// {
//   id: 2,
//   logoImage: '/assets/images/Estimate/sofa.jpg',
//   bannerImage: '/assets/images/Home/nightblackout.jpg',
//   alt: 'night blackout curtain',
//   content: 'NIGHT BLACKOUT\nCURTAIN',
//   price: 200,
//   category: 'blackout',
//   title: 'NIGHT BLACKOUT CURTAIN',
//   theme: 'right-align',
//   highlight: 'Immerse Yourself in Dream Darkness!',
//   description:
//     "Our Blackout Curtains are meticulously crafted to block out sunlight and external distractions, ensuring a restful and peaceful ambiance for a good night's sleep. Enjoy privacy, enhance your home experience.",
// },
// {
//   id: 3,
//   logoImage: '/assets/images/Estimate/bed.png',
//   bannerImage: '/assets/images/Home/blackout.jpg',
//   alt: 'blackout sheer combined',
//   content: 'BLACKOUT SHEER\nCOMBINED',
//   price: 320,
//   category: 'sheerBlackout',
//   title: 'BLACKOUT & SHEER COMBINED ',
//   theme: 'left-align',
//   highlight: 'Switch the Best of Both Worlds!',
//   description:
//     'Enjoy privacy without sacrificing natural light. Our White Sheer Curtains strike the perfect balance, allowing sunlight to filter through while providing a gentle, soft glow that adds warmth and elegance to your rooms.',
// },
// {
//   id: 4,
//   logoImage: '/assets/images/Estimate/chair.png',
//   bannerImage: '/assets/images/Home/roller.jpg',
//   alt: 'roller blind curtain',
//   content: 'ROLLER BLIND\nCURTAIN',
//   price: 120,
//   category: 'blinds',
//   title: 'ROLLER BLIND CURTAIN',
//   theme: 'right-align',
//   highlight: 'Embrace Minimalism Sleek & Stylish!',
//   description:
//     'Our Roller Blinds offer a sleek and contemporary solution for light control and privacy. Enjoy a clutter-free window with these space-saving blinds that effortlessly blend into any interior, adding a touch of modern sophistication.',
// },

const curtainTypesData = [
  {
    id: 1,
    logoImage: '/assets/images/Estimate/American-Sheer-curtain.jpg',
    bannerImage: '/assets/images/Home/American-Sheer-curtain.jpg',
    alt: 'american sheer curtain',
    content: 'AMERICAN SHEER\nCURTAIN',
    price: 80,
    category: 'sheer',
    title: 'American Sheer Curtain',
    theme: 'left-align',
    highlight: 'Privacy with a Soft, Elegant Glow',
    description:
      'American sheer curtains offer the perfect solution for light control and privacy while adding a refined touch to your interiors. With their airy, lightweight fabric, these curtains allow natural light to filter through, creating a warm and open ambiance. Ideal for both modern and classic home designs, these versatile sheer curtains effortlessly complement any decor style.',
    specification1: 'Enhance privacy without compromising natural light',
    specification2: 'Stylish, elegant, and functional for contemporary and traditional spaces',
  },
  {
    id: 2,
    logoImage: '/assets/images/Estimate/American-Blackout-Curtain.jpeg',
    bannerImage: '/assets/images/Home/American-Blackout-Curtain.jpeg',
    alt: 'American blackout Curtain',
    content: 'AMERICAN BLACKOUT\nCURTAIN',
    price: 120,
    category: 'blackout',
    title: 'American Blackout Curtain',
    theme: 'right-align',
    highlight: 'Block the Light, Embrace Comfort',
    description:
      'American blackout curtains are the ultimate solution for complete darkness and privacy. Ideal for restful sleep or movie nights, they block out all light while improving energy efficiency by keeping rooms cooler in summer and warmer in winter.',
    specification1: 'Full light-blocking for privacy and comfort',
    specification2: 'Energy-efficient and noise-reducing design',
  },
  {
    id: 3,
    logoImage: '/assets/images/Estimate/American-Blackout&Sheer-Curtain.jpeg',
    bannerImage: '/assets/images/Home/American-Blackout&Sheer-Curtain.jpeg',
    alt: 'American Blackout & Sheer Curtain',
    content: 'AMERICAN BLACKOUT&SHEER\nCURTAIN',
    price: 160,
    category: 'blackoutSheer',
    title: 'American Blackout & Sheer Curtain',
    theme: 'left-align',
    highlight: 'Seamless Light and Privacy Control Anytime',
    description:
      'Experience the ideal balance of light and privacy with American blackout and sheer curtains. Let natural light filter through the sheers during the day, then block out light and noise at night with the blackout layer. These curtains ensure complete privacy, restful sleep, and a stylish upgrade for any room. Perfect for bedrooms, living rooms, and more.',
    specification1: 'Dual-layer curtains for light and noise control',
    specification2: 'Sleek, functional, and adaptable to any room ',
  },
  {
    id: 4,
    logoImage: '/assets/images/Estimate/Wave-Sheer-curtain.jpg',
    bannerImage: '/assets/images/Home/Wave-Sheer-Curtain.jpg',
    alt: 'Wave sheer curtain',
    content: 'WAVE SHEER\nCURTAIN',
    price: 110,
    category: 'sheer',
    title: 'Wave Sheer Curtain',
    theme: 'right-align',
    highlight: 'Graceful Waves, Effortless Elegance',
    description:
      'Wave sheer curtains add a soft, flowing elegance to your home, combining natural light control with privacy. Their modern design creates a relaxed, airy ambiance, perfect for living rooms, bedrooms, or any space. These sophisticated curtains complement any decor with their timeless charm.',
    specification1: 'Stylish, airy design for light and privacy',
    specification2: 'Perfect for modern and classic interiors',
  },
  {
    id: 5,
    logoImage: '/assets/images/Estimate/Wave-Blackout-Curtain.jpeg',
    bannerImage: '/assets/images/Home/Wave-Blackout-Curtain.jpeg',
    alt: 'wave blackout curtain',
    content: 'WAVE BLACKOUT\nCURTAIN',
    price: 140,
    category: 'blackout',
    title: 'Wave Blackout Curtain',
    theme: 'left-align',
    highlight: 'Wave Elegance, Absolute Darkness',
    description:
      'Wave blackout curtains combine elegant style with complete darkness. The chic wave design adds a modern touch, while the blackout layer ensures total privacy and energy efficiency, creating the perfect atmosphere for relaxation or focus.',
    specification1: 'Elegant wave design with blackout functionality',
    specification2: 'Enhances room temperature control',
  },

  {
    id: 6,
    logoImage: '/assets/images/Estimate/Wave-Blackout&Sheer-Curtain.jpg',
    bannerImage: '/assets/images/Home/Wave-Blackout&Sheer-Curtain.jpg',
    alt: 'Wave - Blackout & Sheer Curtain',
    content: 'WAVE BLACKOUT&SHEER\nCURTAIN',
    price: 180,
    category: 'blackoutSheer',
    title: 'Wave Blackout & Sheer Curtain',
    theme: 'right-align',
    highlight: 'Sculpted Waves, Seamless Light Control',
    description:
      'Achieve the perfect balance of light and privacy with wave blackout & sheer curtains. The sheer layer lets in natural light, while the blackout layer provides complete privacy and darkness when needed. Their stylish wave design adds sophistication to any room.',
    specification1: 'Dual-layer curtains for light and privacy',
    specification2: 'Stylish wave design for any decor',
  },
  {
    id: 7,
    logoImage: '/assets/images/Estimate/Rollar-blinds.jpg',
    bannerImage: '/assets/images/Home/Rollar-blinds.jpg',
    alt: 'Roller Blinds',
    content: 'ROLLER\nBLINDS',
    price: 95,
    category: 'blinds',
    title: 'Roller Blinds',
    theme: 'left-align',
    highlight: 'Effortless Style, Perfect Privacy',
    description:
      'Roller blinds provide sleek, modern window treatments that blend form and function. Easy to operate and chic in design, they deliver excellent privacy and light control while adding a minimalist touch to any room. Practical and stylish, they fit seamlessly into your home.',
    specification1: 'Sleek and modern minimalist style',
    specification2: 'Easy-to-use blinds for privacy and light control',
  },
  {
    id: 8,
    logoImage: '/assets/images/Estimate/Roman-Blinds.jpeg',
    bannerImage: '/assets/images/Home/Roman-Blinds.jpeg',
    alt: 'Roman Blinds',
    content: 'ROMAN\nBLINDS',
    price: 120,
    category: 'blinds',
    title: 'Roman Blinds',
    theme: 'right-align',
    highlight: 'Classic Charm, Timeless Elegance',
    description:
      "Enhance your space with the luxurious appeal of Roman blinds. Perfect for adding warmth and sophistication, these blinds offer excellent light control and privacy. Whether you're creating a cozy retreat or a stylish statement, Roman blinds combine beauty and functionality seamlessly.",
    specification1: 'Elegant design for a cozy or stylish space',
    specification2: 'Exceptional light and privacy control',
  },
  {
    id: 9,
    logoImage: '/assets/images/Estimate/Venetien-Blinds.jpeg',
    bannerImage: '/assets/images/Home/Venetien-Blinds.jpeg',
    alt: 'Venetien Blinds',
    content: 'VENETIEN\nBLINDS',
    price: 90,
    category: 'blinds',
    title: 'Venetien Blinds',
    theme: 'left-align',
    highlight: 'Timeless Style, Ultimate Control',
    description:
      'Venetian blinds are the epitome of functionality and style. Their adjustable slats allow precise control over light and privacy, while their sleek, timeless design adds sophistication to any space. Ideal for both modern and classic interiors.',
    specification1: 'Adjustable slats for light and privacy',
    specification2: 'Sophisticated design for all interiors',
  },
  {
    id: 10,
    logoImage: '/assets/images/Estimate/Vertical-Blinds.jpg',
    bannerImage: '/assets/images/Home/Vertical-Blinds.jpg',
    alt: 'Vertical Blinds',
    content: ' VERTICAL\nBLINDS',
    price: 90,
    category: 'blinds',
    title: ' Vertical Blinds',
    theme: 'right-align',
    highlight: 'Maximize Light, Minimize Clutter',
    description:
      'Vertical blinds offer a stylish solution for wide windows or sliding doors. With easy-to-adjust slats, they allow you to control light and privacy effortlessly while maintaining a clean, modern look. Perfect for both residential and commercial spaces.',
    specification1: 'Smart, space-saving design',
    specification2: 'Ideal for wide windows and sliding doors',
  },
];

export { curtainTypesData };
